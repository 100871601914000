import React from 'react'
import Layout from "../../components/App/Layout"
import Navbar from "../../components/App/Navbar"
import ContentBanner from '../../components/Common/ContentBanner'
import Footer from "../../components/App/Footer"
import PhotoContestCampaign from '../../components/Examples/PhotoContestCampaign'
import ProjectStartArea from '../../components/Common/StartProject';
import SEO from '../../components/App/SEO';
import exampleMetaImage from '../../assets/images/examples/photoContest/photo-contest-meta.jpg';
// Functional component
const Examples = () => {

  return (
	<Layout>
	  <SEO
		title="フォトコンテストの事例・サンプル"
		desc="リタッチのギャラリーを使用して投票機能をONにするだけで、TwitterとInstagramと連動したフォトコンテストを開催できます。"
		banner={exampleMetaImage}
		pathname={'/examples/photo-contest'}
		type={'Article'}
		shortName="フォトコンテストの事例・サンプル"
		createdAt="2021-03-02"
	  />
	  <Navbar />
	  <ContentBanner
		  pageTitle="フォトコンテスト"
		homePageText="Home"
		homePageUrl="/"
		activePageText="フォトコンテスト"
		subDirectories={[{
		  url: '/examples',
		  name: '事例・サンプル'
		}]}
	  />
	  <PhotoContestCampaign />

	  <ProjectStartArea />
	  <Footer />
	</Layout>
  );
}

export default Examples