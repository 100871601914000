import React, {useState} from 'react';
import {Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel} from 'react-accessible-accordion';
import PopularExample from './PopularExample';
import WrapperStyle from './examples.style'
import Drawer from '../../components/UiComponent/drawer'
import {StaticImage} from 'gatsby-plugin-image';
import Gallery from '../../components/UiComponent/gallery'

const OurSolutions = () => {
    const [visible, setVisible] = useState(false);
    const [promotionId, setPromotionId] = useState(null);
    const [drawerTitle, setDrawerTitle] = useState(null);
    const showDrawer = (id, title) => {
        setVisible(true);
        setPromotionId(id);
        setDrawerTitle(title)
    };
    return (
      <WrapperStyle>
        <section className="solutions-area pt-100 pb-70">
            <div className="container">
                <div className="section-title">
                    <h1>フォトコンテスト
                    </h1>
                    <p>
                        リタッチのギャラリーを使用して投票機能をONにするだけで、TwitterとInstagramと連動したフォトコンテストを開催できます。
                    </p>
                </div>
                <div className="row">
                    <div className="col-lg-12 col-sm-12">
                        <Gallery id={"44wfxikmbxr5hm"} visible={true}/>
                    </div>
                </div>
            </div>
        </section>
          <section className="ptb-100 top-question" >
              <div className="container">
                  <div className="section-title">
                    <span className="sub-title">
                        <StaticImage src="../../assets/images/star-icon.png" alt="features" />
                        Question
                    </span>
                      <h2 id="question">よくあるご質問</h2>
                  </div>
                  <div className="faq-accordion">
                      <Accordion allowMultipleExpanded={true}>
                          <AccordionItem>
                              <AccordionItemHeading>
                                  <AccordionItemButton>
                                      どうやって参加者はどうやってフォトコンテストに参加できますか？
                                  </AccordionItemButton>
                              </AccordionItemHeading>
                              <AccordionItemPanel>
                                  <p>
                                      フォトコンテストで使用するハッシュタグを付けて,InstagramかTwitterで写真を投稿してください。リタッチが自動でハッシュタグを条件にコンテンツを収集します。
                                  </p>
                              </AccordionItemPanel>
                          </AccordionItem>
                          <AccordionItem>
                              <AccordionItemHeading>
                                  <AccordionItemButton>
                                      ギャラリーにアップロードで写真を投稿できますか？
                                  </AccordionItemButton>
                              </AccordionItemHeading>
                              <AccordionItemPanel>
                                  <p>
                                      いいえ。現在ギャラリーではSNSに投稿したコンテンツを取得しますので、アップロードで投稿することはできません。
                                  </p>
                              </AccordionItemPanel>
                          </AccordionItem>
                          <AccordionItem>
                              <AccordionItemHeading>
                                  <AccordionItemButton>
                                      参加者の情報は取得できますか？
                                  </AccordionItemButton>
                              </AccordionItemHeading>
                              <AccordionItemPanel>
                                  <p>
                                      SNSのアカウントを取得することが可能です。Twitterはハッシュタグのみで参加者のアカウント情報を取得できます。<br/>
                                      Instagramはハッシュタグと@メンションタグが必要になります。
                                  </p>
                              </AccordionItemPanel>
                          </AccordionItem>
                          <AccordionItem>
                              <AccordionItemHeading>
                                  <AccordionItemButton>
                                      写真を公開しないクローズドなフォトコンテストを開催できますか？
                                  </AccordionItemButton>
                              </AccordionItemHeading>
                              <AccordionItemPanel>
                                  <p>
                                      はい。写真を公開しない場合は、キャンペーン機能を使ってフォトコンテストを開催してください。
                                  </p>
                              </AccordionItemPanel>
                          </AccordionItem>
                          <AccordionItem>
                              <AccordionItemHeading>
                                  <AccordionItemButton>
                                      フォトコンテストにふさわしくない写真が公開された場合は、どうすればいいですか？
                                  </AccordionItemButton>
                              </AccordionItemHeading>
                              <AccordionItemPanel>
                                  <p>
                                      管理画面から公開・非公開にすることでギャラリーに表示されなくなります。不要な写真は削除することも可能です。
                                  </p>
                              </AccordionItemPanel>
                          </AccordionItem>
                      </Accordion>
                  </div>
              </div>
          </section>
          <PopularExample exclude={['photoContest']} showDrawer={showDrawer}/>
          <Drawer visible={visible}id={promotionId} drawerTitle={drawerTitle} setVisible={setVisible}/>
      </WrapperStyle>
    )
};

export default OurSolutions